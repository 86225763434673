<template>
    <div>

        <el-card >


            <el-table
                :data="tableData" border
                style="width: 100%">

                <el-table-column
                    prop="userId"
                    label="用户id"
                    >
                </el-table-column>


                <el-table-column
                    prop="username"
                    label="昵称"
                    >
                </el-table-column>

                <el-table-column
                    prop="createTime"
                    width="190"
                    label="时间"
                    >
                </el-table-column>

                <el-table-column prop="gptKey" label="key(最后四位)" width="160">
                    <template slot-scope="scope">
                        <div>{{ scope.row.gptKey | filterKey }}</div>
                    </template>
                </el-table-column>
            

                

                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120">
                    <template slot-scope="scope">
                        <el-button
                        @click.native.prevent="changeRow(scope.row)"
                        type="text"
                        size="small">
                        完整会话记录
                        </el-button>
                    </template>
                </el-table-column>
            
            </el-table>

            <el-pagination
                :page-size="pageSize"
                :current-page="pageNumber"
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="total">
            </el-pagination>

        </el-card>


        <el-dialog
            title="对话记录"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
                
                <div class="dialogWrap">
                    <json-viewer :value="curDetail" :expand-depth=4 copyable  sort></json-viewer>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">关闭</el-button>
                </span>
        </el-dialog>



    </div>
</template>
  
  
<script>
  // 引入组件及方法
  
  export default {
    components: {  },
    data() {
      return {
        tableData:[],
        total:0,
        pageNumber:0,
        pageSize:10,
        dialogVisible:false,
        curDetail:{}
      };
    },
    filters:{
        filterKey(val){
            //截取字符串最后四位
            return val.substring(val.length-4)
        }
    },
    mounted(){

      this.getData()



    },
    methods: {
        // 获取数据
        getData() {
            
            this.$https('GET_PROMPT_LOG_LIST', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                payType: 1
            }).then(res => {
                this.tableData = res.data.records
                this.total = parseInt(res.data.total)
            })
        },
        changeRow(item){
            this.curDetail = item.useValueJson

            this.dialogVisible = true
        },
        handleClose(){
            this.dialogVisible = false

        },
        setRadioList(){

            if(this.curEditStatus == 1){
                this.radioList.forEach((val)=>{
                    val.disabled = false
                })
            }else if(this.curEditStatus == 2){
                this.radioList.forEach((val)=>{
                    if(val.label == 3 || val.label == 4 || val.label == 5){
                        val.disabled = false
                    }else if(val.label == 2){
                        val.disabled = false
                    }else{
                        val.disabled = true
                    }
                })
            }else if(this.curEditStatus == 3 || this.curEditStatus == 4 || this.curEditStatus == 5){
                this.radioList.forEach((val)=>{
                    val.disabled = true
                })
            }

        },
        async sureChangeClick(){

            var res = await this.$https('UPDATE_ORDER_WITHDRAW_STATUS', {
                state: this.curEditStatus,
                id: this.curEditId
            })
            console.log("更新status", res)
            
            //  Submitted: 提交未处理
            //  Paused: 暂缓
            //  Frozen: 冻结
            //  Completed: 已完成
            //  Refused: 退回



            this.dialogVisible = false
            this.getData()

        },
        handleSizeChange(size){
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num){
            this.pageNumber = num
            this.getData()
        }
    },
  };
</script>
  
<style scoped >
.dialogWrap{
    padding: 20px 0 20px 80px;
    box-sizing: border-box;
    
}
.radioWrap{
    padding: 10px 0;
}
</style>
  